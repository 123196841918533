import "./App.scss";
import { HelmetProvider } from "react-helmet-async";
import Router from "./router";
import { fetchCampainInfo } from "./services";
import variables from "./variables.scss";

import { useEffect, useState } from "react";

import { LOCAL_STORAGE_KEY, STALE_TIME } from "./const";
import {
  DEFAULT_CAMPAIGN,
  DEFAULT_LANG,
  DEFAULT_LOCATION,
} from "./pages/product_alternatives/searchOptionsReducer";

import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { IntlProvider } from "react-intl";
import { ConfigProvider, theme } from "antd";
import { getLocaleMessages } from "./locales";
import { UserConfigContext, initialState } from "./Contexts/UserConfigContext";
import { matchPath } from "react-router";

import ReactGA from "react-ga4";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true,
      cacheTime: STALE_TIME, // 24 hours
      staleTime: STALE_TIME,
    },
    mutations: {
      refetchOnWindowFocus: false, // default: true,
      cacheTime: STALE_TIME, // 24 hours
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

function App() {
  const [isLoading, setIsLoading] = useState(false);

  const match = matchPath(
    {
      path: "/:locale/*",
      exact: true,
      strict: false,
    },
    window.location.pathname
  );

  const { locale = "" } = match?.params || {};
  const [, initialLang = DEFAULT_LANG] = locale?.split("-");

  const [userConfig, setUserConfig] = useState({
    ...initialState,
    lang: initialLang,
    location: DEFAULT_LOCATION,
  });

  async function setupUserConfig() {
    const config = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!config) {
      setIsLoading(true);
      try {
        const campaign = await fetchCampainInfo(DEFAULT_CAMPAIGN);
        const initialConfig = {
          location: DEFAULT_LOCATION,
          campaignName: DEFAULT_CAMPAIGN,
          includes: campaign.campaign_type === "included",
          countries: campaign.countries,
          manufacturers: campaign.manufacturers,
          campaign_images: campaign.images,
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(initialConfig));
        setUserConfig((prev) => {
          return { ...prev, initialConfig };
        });
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
      return;
    }
    setUserConfig((prev) => {
      return { ...prev, initialConfig: JSON.parse(config) };
    });
  }

  useEffect(() => {
    try {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
      console.log(
        "Google Analytics initalized :DONE",
        process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
      );
    } catch (e) {
      console.log("Google Analytics is not Initialized :ERROR");
    }


    setupUserConfig();
  }, []);

  const { lang } = userConfig;
  const setLang = (newLang) => {
    setUserConfig((config) => {
      return { ...config, lang: newLang };
    });
  };

  return (
    <>
      <UserConfigContext.Provider
        value={{ ...userConfig, setLang, setUserConfig }}
      >
        <IntlProvider
          messages={getLocaleMessages(lang)}
          locale={lang}
          defaultLocale={lang}
        >
          <ConfigProvider
            direction={lang === "ar" ? "rtl" : "ltr"}
            theme={{
              algorithm: theme.lightAlgorithm,
              token: {
                colorPrimary: variables.primaryColor,
              },
            }}
          >
            <HelmetProvider>
              <PersistQueryClientProvider
                client={queryClient}
                persistOptions={{ persister, maxAge: STALE_TIME }}
              >
                {!isLoading ? <Router /> : null}
              </PersistQueryClientProvider>
            </HelmetProvider>
          </ConfigProvider>
        </IntlProvider>
      </UserConfigContext.Provider>
    </>
  );
}

export default App;
