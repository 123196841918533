export const countriesList = [
  {
    key: "1",
    label: "Egypt",
    code: "eg",
  },
  {
    key: "2",
    label: "Saudi Arabia",
    code: "sa",
  },
  {
    key: "3",
    label: "United Arab Emirates",
    code: "ae",
  },
  {
    key: "4",
    label: "Morocco",
    code: "ma",
  },
  {
    key: "5",
    label: "Jordan",
    code: "jo",
  },
  {
    key: "6",
    label: "Kuwait",
    code: "kw",
  },
  {
    key: "7",
    label: "Qatar",
    code: "qa",
  },
  {
    key: "8",
    label: "Oman",
    code: "om",
  },
  {
    key: "9",
    label: "Bahrain",
    code: "bh",
  },
  {
    key: "10",
    label: "Lebanon",
    code: "lb",
  },
  {
    key: "11",
    label: "Iraq",
    code: "iq",
  },
  {
    key: "12",
    label: "Türkiye",
    code: "tr",
  },
];

export const LOCAL_STORAGE_KEY = "badayli_user_config";
export const PALESTINE_SUPPORT_KEY = "palestine_support";
export const STALE_TIME = 1000 * 60 * 60 * 2; // 2 hours cache
