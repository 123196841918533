// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;600;800&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Changa:wght@400;600&family=Figtree:wght@300;400;600;800&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=El+Messiri&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Figtree", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-layout-rtl * {
  font-family: "El Messiri", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAIA;EACE,SAAA;EACA,kCAAA;AAAF;;AAGA;EACE,+EAAA;AAAF;;AAKE;EACE,qCAAA;AAFJ","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;600;800&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Changa:wght@400;600&family=Figtree:wght@300;400;600;800&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=El+Messiri&display=swap\");\n\nbody {\n  margin: 0;\n  font-family: \"Figtree\", sans-serif;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n.ant-layout-rtl {\n  * {\n    font-family: \"El Messiri\", sans-serif;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
